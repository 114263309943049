function Banner() {
    return (
        <div className="container hero-banner-container reveal" id="home">
        <div className="wrapper">
          <div className="content-wrapper">
            <span className="sub-text">"Join"
UNLOCK  YOUR CHILD  POTENTIAL</span>
            <h1 className="title">Welcome to Aalayam Rehab Centre</h1>
            <p className="description">
          
              Aalayam Rehab Centre has been established to cater to the children
              with developmental disabilities such as Autism spectrum disorder (ASD), Attention deficit disorder(ADHD), Cerebral palsy, Mental retardation MR, Down syndrome. We offer physiotherapy ,
              occupational therapy , behaviour therapy and special education to
              the children with neurological disorder.
            </p>
          </div>
          <div className="img-container"></div>
        </div>
      </div>
    );
  }
  
  export default Banner;
  