
function Copyright() {
    return (
        <div className="copyright">
        <p>Copyright @2024 AalayamRehabCentre. All Rights Reserved by <a target='_blank' rel="noreferrer" href='https://www.eooglo.com'><span>Eooglo</span></a></p>
      </div>
    );
  }
  
  export default Copyright;
  